import React from "react"
import MainFooter from "../../../footer/main_footer"
import MainHeader from "../../../main_header/main_header"
import * as classes from "./learning_development_consultation.module.scss"
import loadable from "@loadable/component"
const LearningBanner = loadable(() => import("./banner"), {
  fallback: <div></div>,
})

export default function LearningDevelopment() {
  return (
    <React.Fragment>
      <MainHeader />
      <LearningBanner />
      <div className={classes.text_div}>
        <h2>Learning and Development Consultation</h2>
        <p>
          What is the Master Trainers’ Learning and Development (L&D) system? To
          put it simply, it manages the training, learning and development
          initiatives of businesses. The aim is to create a culture of constant
          learning and growth through programmes that allow organizations to
          continuously evolve and develop. Their responsibilities vary depending
          on the company, but often include the implementation of learning
          strategies, upskilling the workforce, enhancing employee participation
          and engagement and assessments on jobs etc. All our customers deserve
          to experience the impeccable level of professionalism when working
          with us. You can trust that we will supply you with the best content,
          as well as top notch platform development.
        </p>
      </div>
      <MainFooter />
    </React.Fragment>
  )
}
